<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <div class="progress-wrapper">
              <b-card-text>
                Số tín chỉ tích lũy: {{ accumulatedCredits }}
              </b-card-text>
              <b-progress
                v-model="accumulatedCredits"
                height="2rem"
                variant="primary"
                :max="totalCredits"
                show-value
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-for="(process, idx) in processes"
      :key="idx"
      class="match-height my-1"
    >
      <b-col
        cols="12"
        md="2"
        lg="1"
      >
        <b-card
          no-body
          :bg-variant="process.bgVariant"
          class="text-white"
        >
          <b-card-body class="p-25">
            <div class="truncate">
              <span
                class="text-white text-break mb-0"
                style="font-size: 18px"
              >
                {{ process.courseSemesterName }}
              </span>
              <hr>
              <span style="font-size: 18px">{{ process.totalCreditStudied }} tín chỉ</span>
              <hr>
              <span style="font-size: 18px">{{ process.trang_thai }}</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="10"
        lg="11"
      >
        <div
          id="processes-container"
          class="d-flex flex-wrap"
        >
          <b-card
            v-for="(subject, key) in process.subjects"
            :key="key"
            class="process-card programme-subject mx-50 mb-1 mb-md-2"
          >
            <h4
              v-b-tooltip.hover.top="subject.name"
              class="text-truncate"
            >
              {{ subject.name }}
            </h4>
            <hr class="mb-2">
            <div class="d-flex justify-content-between align-items-top">
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Điểm số
                </h6>
                <h3 class="mb-0">
                  {{ subject.averagePoint }}
                </h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Điểm chữ
                </h6>
                <h3 class="mb-0">
                  <span :class="[subject.bgAlphabetPoint]">{{ subject.alphabetPoint }}</span>
                </h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Số TC
                </h6>
                <h3 class="mb-0">
                  {{ subject.totalCreditNum }}
                </h3>
              </div>
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCol, BOverlay, BProgress, BRow, VBTooltip,
} from 'bootstrap-vue'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Process',
  components: {
    BOverlay,
    BCard,
    BCardBody,
    BProgress,
    BCardText,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      dataSources: [],
      bgVariant: 'info',
    }
  },
  computed: {
    ...mapGetters({
      accumulatedCredits: 'study/accumulatedCredits',
      totalCredits: 'study/totalCredits',
      processes: 'study/processes',
    }),
  },
  async created() {
    await this.getDataSourcesFromStore()
  },
  methods: {
    ...mapActions({
      getStudyProcess: 'study/getStudyProcess',
    }),
    async getDataSourcesFromStore() {
      const programmeId = this.user.portalProgrammeId
      if (!programmeId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sinh viên không theo học chương trình đào tạo nào',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const { programmes } = this.user
      const programme = programmes.length > 0 && programmes.find(item => item.id === programmeId)
      if (!programme) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sinh viên không theo học chương trình đào tạo nào',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        await this.getStudyProcess({ programmeId, studentClassId: programme.studentClassId })
        if (this.processes.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.processes.length; i++) {
            const item = this.processes[i]
            this.processes[i].trang_thai = ''
            this.processes[i].bgVariant = 'success'
            for (let j = 0; j < this.processes[i].subjects.length; j++) {
              this.processes[i].subjects[j].bgAlphabetPoint = this.getPointColor(this.processes[i].subjects[j].alphabetPoint)
            }
            if (item.totalCreditStudied) {
              this.processes[i].trang_thai = 'Đã học'
            }
            if (item.status === 1) {
              this.processes[i].trang_thai = 'Đang học'
              this.processes[i].bgVariant = 'info'
            }
            if (!item.totalCreditStudied) {
              this.processes[i].trang_thai = 'Chưa học'
              this.processes[i].bgVariant = 'warning'
            }
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getPointColor(name) {
      switch (name) {
        case 'A':
          return 'text-success'
        case 'B':
          return 'text-info'
        case 'C':
          return 'text-secondary'
        case 'D':
          return 'text-warning'
        default:
          return 'text-primary'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/include.scss';

#processes-container {
  .process-card {
    width: 250px;
  }
}

// Active Card
.process-card {
  border: 1px solid transparent;
  &.active {
    border-color: $primary;
    i,
    svg {
      color: $primary;
    }
  }
}

@media (max-width: 1024px) {
  #processes-container {
    .process-card {
      width: 198px;
    }
  }
}

@media (max-width: 768px) {
  #processes-container {
    .process-card {
      width: 203px;
    }
  }
}

@media (max-width: 414px) {
  #processes-container {
    .process-card {
      width: 182px;
    }
  }
}

@media (max-width: 375px) {
  #processes-container {
    .process-card {
      width: 222px;
    }
  }
}
.text-note {
  display: inline-flex;
}
.note {
  width: 30px;
  height: 20px;
  margin-right: 40px;
}

.note-1 {
  background-color: #28c76f ;
}

.note-2 {
  background-color: #00cfe8  ;
}

.note-3 {
  background-color: #ff9f43  ;
}
</style>
